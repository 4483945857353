import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from '../Home';
import Markdown from '../Markdown';
import privacySource from '../../assets/privacy.md';
import termsSource from '../../assets/terms.md';

import {
    Container,
    HeaderLogo,
    Footer,
    FooterContent,
    FooterLink
} from './styles';

const PageTemplate = ({ children }) =>
    <Router>
        <Container>
            <Link to="/"><HeaderLogo src='assets/logo.svg' /></Link>

            <Switch>
                <Route key="privacy" path="/privacy"><Markdown source={privacySource} /></Route>
                <Route key="terms" path="/terms"><Markdown source={termsSource} /></Route>
                <Route key="home" path="/"><Home /></Route>
            </Switch>

            <Footer>
                <FooterContent>
                    <FooterLink><Link to="/privacy">Privacy Policy</Link></FooterLink>
                    <FooterLink><Link to="/terms">Terms</Link></FooterLink>
                    <FooterLink><a href="mailto:contact@urated.app">Contact</a></FooterLink>
                    <FooterLink><a href="https://www.blockchain.com/btc/address/bc1q03qqnkjxczwf70dkm8ph32d5dk73x6ranpezp3">Donate Bitcoin</a></FooterLink>
                </FooterContent>
            </Footer>
        </Container>
    </Router >

export default PageTemplate;
