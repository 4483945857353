import styled from 'styled-components';

export const Content = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 10px;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 700px) {
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        margin-top: 0px;
    }
`;

export const TextContainer = styled.div`
    text-align: center;
`;

export const Title = styled.h1`
    font-size: 70px;
    font-family: 'vt323';

    @media (max-width: 700px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

export const SubTitle = styled.h2`
    font-size: 30px;
    font-family: 'ubuntu';
    margin-left: 30px;
    margin-right: 30px;
`;

export const Description = styled.div`
    font-family: 'ubuntu';
    text-align: justify;
    margin: 30px;
`;

export const StoreBadge = styled.img`
    height: 45px;
    margin: 10px;
`;

export const ScreenshotContainer = styled.div`
    text-align: center;

    @media (max-width: 700px) {
        margin-top: 20px;
    }
`;

export const ScreenshotImage = styled.img`
    height: 480px;
`;
