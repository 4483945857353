import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Content } from './styles';

const PrivacyPolicy = ({ source }) => {
    const [content, setContent] = useState();

    useEffect(() => {
        fetch(source)
            .then(res => res.text())
            .then((text) => setContent(text));
    }, [source]);

    return <Content>
        <ReactMarkdown source={content} />
    </Content>
}

export default PrivacyPolicy;
