import React from 'react';
import {
    Content,
    ScreenshotContainer,
    ScreenshotImage,
    TextContainer,
    Title,
    SubTitle,
    Description,
    StoreBadge
} from './styles';

const Home = () =>
    <Content>
        <TextContainer>
            <Title>You rated, We plotted</Title>
            <SubTitle>See your favorite TV shows episodes ratings on a graph in the U-Rated app</SubTitle>
            <Description>Ever wondered which episode of your favorite TV Show was the best (or maybe the worst...)? Search through thousands of TV shows or look at the top TV shows of the moment. Free and no sign up required, download the U-Rated app now on Android and iOS!</Description>
            <a href="https://play.google.com/store/apps/details?id=app.urated.android"><StoreBadge src="assets/google-play-badge.png" /></a>
            <a href="https://apps.apple.com/us/app/u-rated/id1537221914"><StoreBadge src="assets/Download_on_the_App_Store_Badge.svg" /></a>
        </TextContainer>
        <ScreenshotContainer><ScreenshotImage src='assets/ScreenShot_0.png' /></ScreenshotContainer>
    </Content>

export default Home;
