import { createGlobalStyle } from 'styled-components'
import ubuntuFont from "./assets/fonts/Ubuntu-Regular.ttf";
import vt323Font from "./assets/fonts/VT323-Regular.ttf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: ubuntu;
    src: url(${ubuntuFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: vt323;
    src: url(${vt323Font}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body {
    background: #222;
    color: white;
    padding: 0;
    margin: 0;
  }
`;
