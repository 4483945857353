import styled from 'styled-components';

export const Content = styled.div`
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 40px;
    font-family: 'ubuntu';

    h1 {
        text-align: center;
        margin-bottom: 40px;
        font-size: 3em;
        font-family: 'vt323';
        
    }

    h2 {
        margin-top: 40px;
        font-size: 2em;
        font-family: 'vt323';
    }

    p {
        text-align: justify;
    }
    
    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;